<template>
  <div class="checkout-filter-list">
    <div class="checkout-filter-list__content">
      <CheckboxFilterListItem 
        v-for="(item, index) in dynamicList" 
        :key="item.label_id || index" 
        :language="language" 
        :loaded="loaded" 
        :panel-config="item" 
        :disabled="disabled" 
        :lazy-img="lazyImg"
        :transform-img="transformImg"
        :is-show-title="allList.length > 1"
        @filter-change="filterChange"
      />
    </div>
    <div
      v-if="showViewMore"
      tabindex="0"
      role="button"
      class="checkout-filter-list__viewMore"
      @keydown.space.stop.prevent="clickViewMore(true)"
      @click="clickViewMore()"
    >
      <sui_icon_add_12px
        style="line-height: 12px"
        size="12px"
      />
      {{ language.SHEIN_KEY_PC_15600 }}
    </div>
  </div>
</template>

<script>
import { sui_icon_add_12px } from '@shein-aidc/icon-vue3'
import CheckboxFilterListItem from './CheckboxFilterListItem.vue'
export default {
  name: 'CheckboxFilterList',
  components: {
    sui_icon_add_12px,
    CheckboxFilterListItem,
  },
  props: {
    language: {
      type: Object,
      default: () => ({}),
    },
    loaded: {
      type: Boolean,
      default: false,
    },
    panelConfig: {
      type: Object,
      default: () => ({}),
    },
    disabled: {
      type: Boolean,
      default: false
    },
    // 懒加载占位图
    lazyImg: {
      type: String,
      default: '',
    },
    // 处理图片
    transformImg: {
      type: [String, Function],
      default: '',
    },
  },
  data() {
    return {
      isClickViewMore: false
    }
  },
  computed: {
    allList () { 
      return this.panelConfig?.data?.children || []
    },
    showViewMore () { 
      return this.allList.length > 2 && !this.isClickViewMore
    },
    dynamicList () { 
      return this.showViewMore 
        ? this.allList.slice(0, 2)
        : this.allList
    }
  },
  watch: {
  },
  methods: {
    // 点击view more
    clickViewMore () {
      this.isClickViewMore = true
    },
    // 点击筛选项
    filterChange (params) {
      this.$emit('filterChange', params)
    },
    resetFilter() {
    },
  },
  emits: ['filterChange', 'resetFilter'],
}
</script>

<style scoped lang="less">
.checkout-filter-list {
  &__content {
    >div {
      margin-top: 13px;

      &:first-child {
        margin-top: 0;
      }
    }
  }

  &__viewMore {
    cursor: pointer;
    margin-top: 11px;
    color: #000;
    font-family: Arial;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-align: center;
  }
}
</style>
